var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.relateds.length && _vm.enabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "추가상품"
    }
  }), _c('v-select', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "return-object": "",
      "placeholder": "추가상품선택",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "disable-lookup": "",
      "items": _vm.relateds
    },
    on: {
      "change": _vm.emit
    }
  }, 'v-select', {
    disabled: _vm.disabled,
    itemText: _vm.itemText,
    itemDisabled: _vm.itemDisabled
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }