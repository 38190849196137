<template>
    <page-section class="page-section--xs pb-0" v-if="relateds.length && enabled">
        <tit-wrap-x-small title="추가상품" />
        <v-select return-object placeholder="추가상품선택" dense outlined hide-details disable-lookup :items="relateds" v-bind="{ disabled, itemText, itemDisabled }" class="rounded-0" @change="emit" />
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        relateds() {
            return (this.product?.relateds || []).filter(({ enabled }) => enabled);
        },
        enabled() {
            return this.product?.relatedsConf?.enabled || false;
        },
    },
    methods: {
        emit(related) {
            if (related && !this.value.find((cart) => cart._related == related._id)) {
                const cart = {
                    _product: this.product._id,
                    _related: related._id,
                    product: this.product,
                    name: related.name,
                    price: related.price,
                    salePrice: related.salePrice,
                    discountPrice: related.discountPrice,
                    stock: related.stock,
                    amount: 1,
                };
                this.$emit("input", [...this.value, cart]);
            }
        },
        itemText({ name, salePrice, stock }) {
            let text = this.$decode__productOptionName(name);
            if (stock) {
                if (salePrice != null) {
                    if (0 <= salePrice) text += ` (+${salePrice.format()}원)`;
                    else text += ` (${salePrice.format()}원)`;
                }
            } else text += " (품절)";
            return text;
        },
        itemDisabled({ stock }) {
            return stock == 0;
        },
    },
};
</script>
