<template>
    <page-section class="page-section--xs pb-0" v-if="items.length">
        <tit-wrap-x-small title="옵션선택" />
        <template v-for="({ name, values }, index) in items">
            <v-select v-model="selected[name]" @change="emit(index)" :placeholder="name" :items="values" item-text="name" item-value="name" dense outlined hide-details :class="{ 'mt-1 mt-md-2': index != 0 }" class="rounded-0" :key="name" />
        </template>
    </page-section>
</template>

<script>
import { PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        criteria: { type: Array, default: () => [] },
    },
    data: () => ({
        selected: {},
    }),
    computed: {
        color() {
            return this.criteria.find(({ type }) => type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value);
        },
        items() {
            return this.criteria.filter(({ type }) => type != PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.selected = { ...this.value };
        },
        emit(index) {
            const names = [...[...this.items].slice(0, index + 1), this.color].map(({ name }) => name);
            for (const name of Object.keys(this.selected)) {
                if (!names.includes(name)) delete this.selected[name];
            }
            this.$emit("input", this.selected);
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-group
.btn-group {
    flex-wrap: wrap;
    margin: -4px;
    .v-btn {
        line-height: 1;
        opacity: 1;
        &.v-size--x-small.v-btn--icon {
            position: relative;
            width: 34px !important;
            height: 34px !important;
            padding: 0 !important;
        }
    }
    &--color {
        .v-btn {
            border-radius: 100% !important;
            overflow: hidden;
            border: 0 !important;
            &--active {
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 2px solid var(--v-grey-darken4) !important;
                }
            }
        }
    }
    &__colorchip {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
</style>
